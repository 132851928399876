<template>
  <div class="realEstatePanel">
    <div class="mt-2" v-if="loaded">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('New real estate') }}
          </h4>
        </div>


        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">
          <!--          <b-col cols="12" md="6" lg="6">
                      <b-form-group :label="msg('HostawayId')" label-for="realEstate-name">
                        <validation-provider #default="{ errors }" :name="msg('HostawayId')" rules="required">
                          <b-form-input id="realEstate-name" v-model="realEstate.hostawayId" type="number"
                                        :state="errors.length > 0 ? false:null"/>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>-->
          <!-- Branch -->
          <b-col cols="12" md="3" lg="3">
            <b-form-group :label="msg('Building')" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('Building')" rules="required">
                <v-select

                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="realEstate.buildingId"
                    :options="allBuildings"
                    label="address"
                    :reduce="(building) => building.id"
                    class="w-100"
                    :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" lg="3">
            <b-form-group :label="msg('Building')" label-for="producgdgfs">

              <b-button @click="goToNewBuilding">{{ msg('New building') }}</b-button>
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Name')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Name')" rules="required">
                <b-form-input id="realEstate-nffdame" v-model="realEstate.name"
                              :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <!-- Field: First name -->
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Address') + (' (Enter only street address)')" label-for="realEstate-name">
              <b-form-input
                  v-model="query"
                  @input="debouncedSearch"
                  placeholder="Search for an address"
              ></b-form-input>
              <!-- Suggestions List -->
              <ul v-if="suggestions.length" class="list-group mt-2">
                <li
                    v-for="(suggestion, index) in suggestions"
                    :key="index"
                    class="list-group-item list-group-item-action"
                    @click="selectSuggestion(suggestion)"
                >
                  <strong>{{ suggestion.address }}</strong><br/>
                  <small v-if="suggestion.city">
                    City: {{ suggestion.city }}
                  </small>
                  <small v-if="suggestion.houseNumber">
                    , House Number: {{ suggestion.houseNumber }}
                  </small>
                </li>
              </ul>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('House number')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('House')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.house" type="number"
                              :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Apt')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Apt')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.apt" type="number"
                              :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('City')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('City')" rules="required">
                <b-form-input id="realEstate-city" v-model="realEstate.city"
                              :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Zip')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Zip')" rules="required">
                <b-form-input id="realEstate-zip" v-model="realEstate.zip" type="number"
                              :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Branch -->
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Branch')" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('Branch')" rules="required">
                <v-select

                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="realEstate.buid"
                    :options="allBranches"
                    class="w-100"
                    label="name"
                    :reduce="(branch) => branch.id"
                    :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Usage type')" label-for="realEstate-type">
              <validation-provider #default="{ errors }" :name="msg('Usage')" rules="required">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="realEstate.usageType"
                    :options="[
                      {value:{business:false,investment:false,living:true,waz:false},label:msg('Living')}
                      ,{value:{business:true,investment:false,living:false,waz:false},label:msg('Business')},
                      {value:{business:false,investment:true,living:false,waz:false},label:msg('Investment')}]"
                    label="label"
                    :reduce="(status) => status.value"
                    class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Real estate type')" label-for="realEstate-type"
                          :description="msg('For rent or sale?')">
              <validation-provider #default="{ errors }" :name="msg('Type')" rules="required">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="realEstate.marketingType"
                    class="w-100"
                    :options="[{value:{purchase:false,rent:true},label:msg('Rent')},{value:{purchase:true,rent:false},label:msg('Purchase')}]"
                    label="label"
                    :reduce="(status) => status.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Rent (for long term rent)')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Price')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.realEstatePrices.rentTotal" type="number"
                              step="0.5" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Owner ID')" label-for="realEstate">
              <client-picker is-owner v-model="realEstate.ownerId"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Base rent price per night (base for the next 2 years)')"
                          label-for="realEstate-ndgdfame">
              <validation-provider #default="{ errors }" :name="msg('Base price')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.realEstatePrices.basePricePerNight" type="number"
                              step="0.5" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: First name -->
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Floor')" label-for="realEstate-name">
              <b-form-input id="realEstate-name" v-model="realEstate.floorNumber" type="number"/>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="5" lg="3">
            <b-form-group :label="msg('Floor text')" label-for="realEstate-name">
              <b-form-input id="realEstate-name" v-model="realEstate.floor"/>

            </b-form-group>
          </b-col>
        </b-row>

        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">



          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Rooms')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Rooms')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.rooms" type="number" min="0"
                              step="1" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Bathrooms')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Bathrooms')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.bathrooms" type="number" min="0"
                              step="1" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Peoples')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Peoples')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.peoples" type="number" min="0"
                              step="1" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Total space')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('total space')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.totalSpace" type="number" min="0"
                              step="0.1" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('CheckIn time')" label-for="messageTemplate-company">
              <validation-provider #default="{ errors }" :name="msg('CheckIn time')" rules="required">

                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    :options="timeOptions"
                    v-model="realEstate.checkInTime"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"
                ></v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('CheckOut time')" label-for="messageTemplate-company">
              <validation-provider #default="{ errors }" :name="msg('CheckOut time')" rules="required">

                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    :options="timeOptions"
                    v-model="realEstate.checkOutTime"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"
                ></v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Minimum stay')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('minimum stay')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.minimumStay" type="number" min="0"
                              step="0.1" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Cleaning Fee')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Cleaning Fee')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.realEstatePrices.cleaningFee" type="number"
                              min="0"
                              step="0.1" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
      </validation-observer>
    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="min-height: 300px;text-align: center">
        <div style="margin-top: 20%">
          <b-spinner label="Spinning"/>
          {{ msg('loading') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import ClientPicker from "@/components/components/picker/clientPicker.vue";

export default {
  name: 'realEstatePanel',
  components: {ClientPicker},
  data() {
    return {
      query: "",
      suggestions: [],
      loaded: false,
      timeOptions:
          [
            {label: "00:00", value: "00:00"},
            {label: "00:30", value: "00:30"},
            {label: "01:00", value: "01:00"},
            {label: "01:30", value: "01:30"},
            {label: "02:00", value: "02:00"},
            {label: "02:30", value: "02:30"},
            {label: "03:00", value: "03:00"},
            {label: "03:30", value: "03:30"},
            {label: "04:00", value: "04:00"},
            {label: "04:30", value: "04:30"},
            {label: "05:00", value: "05:00"},
            {label: "05:30", value: "05:30"},
            {label: "06:00", value: "06:00"},
            {label: "06:30", value: "06:30"},
            {label: "07:00", value: "07:00"},
            {label: "07:30", value: "07:30"},
            {label: "08:00", value: "08:00"},
            {label: "08:30", value: "08:30"},
            {label: "09:00", value: "09:00"},
            {label: "09:30", value: "09:30"},
            {label: "10:00", value: "10:00"},
            {label: "10:30", value: "10:30"},
            {label: "11:00", value: "11:00"},
            {label: "11:30", value: "11:30"},
            {label: "12:00", value: "12:00"},
            {label: "12:30", value: "12:30"},
            {label: "13:00", value: "13:00"},
            {label: "13:30", value: "13:30"},
            {label: "14:00", value: "14:00"},
            {label: "14:30", value: "14:30"},
            {label: "15:00", value: "15:00"},
            {label: "15:30", value: "15:30"},
            {label: "16:00", value: "16:00"},
            {label: "16:30", value: "16:30"},
            {label: "17:00", value: "17:00"},
            {label: "17:30", value: "17:30"},
            {label: "18:00", value: "18:00"},
            {label: "18:30", value: "18:30"},
            {label: "19:00", value: "19:00"},
            {label: "19:30", value: "19:30"},
            {label: "20:00", value: "20:00"},
            {label: "20:30", value: "20:30"},
            {label: "21:00", value: "21:00"},
            {label: "21:30", value: "21:30"},
            {label: "22:00", value: "22:00"},
            {label: "22:30", value: "22:30"},
            {label: "23:00", value: "23:00"},
            {label: "23:30", value: "23:30"}
          ],
      error: null,
      realEstateId: 0,
      realEstate: {
        name: '',
        type: null,
        realEstateArea: {},
        realEstatePrices: {}
      },
      debounceTimer: null, // Timer for debounce
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    id: Number,
    allBuildings: {
      type: Array,
      default: null,
      required: true
    }
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    }
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),


    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  mounted() {
  },
  created() {

    this.onLoad()
    this.$root.$on('crm::submitRealEstate', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitRealEstate', this.listeners.submit)
  },
  methods: {
    ...mapActions('realEstate', {
      _register: 'addRealEstate',
      _loadRealEstateData: 'getData'
    }),
    ...mapActions('realEstate', ['getPlaces']),
    debouncedSearch() {
      clearTimeout(this.debounceTimer); // Clear any existing timer
      this.debounceTimer = setTimeout(() => {
        this.fetchSuggestions();
      }, 1000); // Delay of 1 seconds
    },


    fetchSuggestions() {
      let $this = this;
      if (this.query.trim() === "") {
        this.suggestions = [];
        return;
      }

      this.getPlaces({input: this.query}).then((response) => {
        console.log('response: ', response)
        $this.suggestions = response.Places;
      });
    },

    // Handle suggestion selection
    selectSuggestion(suggestion) {
      // Map the suggestion fields to the realEstate object
      console.log('suggestion: ', suggestion)
      this.realEstate.address = suggestion.street;

      // Set the input field to the selected address
      this.query = suggestion.street;

      // Clear suggestions
      this.suggestions = [];
    },
    onPlaceChanged(place) {
      console.log('place: ', place)
      this.realEstate.address = place.formatted_address;
    }
    ,
    goToNewBuilding() {
      window.open('/buildings', '_blank')
    },
    onLoad() {
      let $this = this;
      this._loadRealEstateData().finally(() => $this.loaded = true)

    }
    ,
    createRealEstate() {
      let $this = this
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this._register(this.realEstate)
            .then(
                (id) => {

                  $this.realEstateId = id
                  $this.$emit('submitted', {
                    status: true,
                    server: id,
                    id

                  })
                },
                (a) => $this.$emit('submitted', {
                  status: false,
                  server: a
                })
            )
            .finally(() => {
              console.log('id: ', $this.realEstateId)
              this.$router.push('/real_estate/edit/' + $this.realEstateId)

            })
      })
    },

    validate(field) {
      return this.$v.realEstate[field] != null && this.$v.realEstate[field].$error === true
    }
    ,
    submit() {
      return this.createRealEstate()
    }

  },

}
</script>


<style>
.list-group-item {
  cursor: pointer;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}
</style>
